import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import jwt_decode from "jwt-decode";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const AUTHORISE = "authorise";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_AUTH_TOKEN = "setAuthToken";
export const SET_ERROR = "setError";
export const SET_CARD_NO = "setCardNo";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, cardNo) {
    return new Promise((resolve, reject) => {
      ApiService.get("login", cardNo)
        .then(response => {
          if (response.data.error) {
            context.commit(SET_ERROR, response.data.error);
            reject({ error: response.data.error });
          } else {
            context.commit(SET_CARD_NO, response.data.cardNo);
            resolve({ data: response.data.cardNo });
          }
        })
        .catch(() => {
          const errMsg = "Błąd połaczenia proszę spróbować później.";
          context.commit(SET_ERROR, errMsg);
          reject({ error: errMsg });
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [AUTHORISE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(response => {
          if (response.data.error) {
            context.commit(SET_ERROR, response.data.error);
            reject({ error: response.data.error });
          } else {
            context.commit(SET_AUTH, response.data.user);
            resolve(response.data.user);
          }
        })
        .catch(() => {
          const errMsg = "Błąd połaczenia proszę spróbować później.";
          context.commit(SET_ERROR, errMsg);
          reject({ error: errMsg });
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(response => {
          context.commit(SET_AUTH, response.data.user);
        })
        .catch(() => {
          context.commit(PURGE_AUTH);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CARD_NO](state, cardNo) {
    state.cardNo = cardNo;
  },

  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    state.user.role = jwt_decode(state.user.token).ROLE;
    JwtService.saveToken(state.user.token);
  },
  [SET_AUTH_TOKEN](state, token) {
    state.isAuthenticated = true;
    state.user.token = token;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.cardNo = null;
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
