import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      // begin agroas router
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/transaction-history",
          name: "transaction-history",
          component: () =>
            import(
              "@/view/pages/customer/transaction-history/TransactionHistoryTable.vue"
            )
        },
        {
          path: "/order-history",
          name: "order-history",
          component: () =>
            import("@/view/pages/customer/order-history/OrderHistory.vue")
        },
        {
          path: "/prizes-catalog",
          name: "prizes-catalog",
          component: () =>
            import("@/view/pages/customer/prizes/PrizeCatalog.vue")
        },
        {
          path: "/punctation",
          name: "punctation",
          component: () =>
            import("@/view/pages/allShared/punctation/Punctation.vue")
        },
        {
          path: "/files",
          name: "files",
          component: () => import("@/view/pages/allShared/files/Files.vue")
        },
        {
          path: "/claims",
          name: "claims",
          component: () => import("@/view/pages/customer/claims/Claims.vue")
        },
        {
          path: "/transfer",
          name: "transfer",
          component: () => import("@/view/pages/customer/transfer/TransferPoints.vue")
        },        
        {
          path: "/checkout",
          name: "checkout",
          component: () => import("@/view/pages/customer/checkout/Checkout.vue")
        },
        // end agroas user router
        {
          path: "/claims-admin",
          name: "claims-admin",
          component: () => import("@/view/pages/admin/claims/ClaimsAdmin.vue")
        },
        {
          path: "/order-admin",
          name: "order-admin",
          component: () => import("@/view/pages/admin/orders/Orders.vue")
        },
        {
          path: "/prizes-catalog-admin",
          name: "prizes-catalog-admin",
          component: () => import("@/view/pages/admin/prizes/PrizesAdmin.vue")
        },
        {
          path: "/transfer-admin",
          name: "transfer-admin",
          component: () =>
            import("@/view/pages/admin/transfer/TransferAdmin.vue")
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/admin/users/Users.vue")
        },
        {
          path: "/auth-history",
          name: "auth-history",
          component: () =>
            import("@/view/pages/admin/reports/authhistory/AuthHistory.vue")
        },
        {
          path: "/gained-points-group",
          name: "gained-points-group",
          component: () =>
            import("@/view/pages/admin/reports/gainedpoints/GainedPointsPerGroup.vue")
        },
        {
          path: "/promotion/:id",
          name: "promotion",
          component: () => import("@/view/pages/admin/promotions/Promotion.vue")
        },
        {
          path: "/promotions",
          name: "promotions",
          component: () =>
            import("@/view/pages/admin/promotions/Promotions.vue")
        }
        // end agroas admin router
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
