import ApiService from "@/core/services/api.service";

// action types
export const ADD_TO_CART = "addToCart";
export const ORDER = "order";
export const DELETE_FROM_CART = "dellFromCart";
export const DESTROY_CART = "destroyCart";

// mutation types
export const SET_CART_POSITION = "setCartPosition";
export const CLEAR_CART = "clearCart";
export const UNSET_CART_POSITION = "removeCartPosition";

const state = {
  cart: []
};

const getters = {
  getCart(state) {
    return state.cart;
  }
};

const actions = {
  [ADD_TO_CART](context, { prize, quantity }) {
    const position = context.state.cart.find(p => p.prize.id === prize.id) || {
      prize: prize,
      quantity: 0
    };

    if (position.quantity + quantity > 0) {
      position.quantity += quantity;
      context.commit(SET_CART_POSITION, position);
    }
  },
  [ORDER](context, { delivery }) {
    const document = {};
    const order = context.state.cart.map(p => {
      return {
        quantity: p.quantity,
        erpPrizeId: p.prize.erpPrize.id,
        cost: p.prize.erpPrize.cost
      };
    });
    document.elements = order;
    document.header = delivery;
    return ApiService.post("/order", document).then(() =>
      context.commit(CLEAR_CART)
    );
  },
  [DELETE_FROM_CART](context, { prize }) {
    const position = context.state.cart.find(p => p.prize.id === prize.id);
    context.commit(UNSET_CART_POSITION, position);
  },
  [DESTROY_CART](context) {
    context.commit(CLEAR_CART);
  }
};

const mutations = {
  [SET_CART_POSITION](state, position) {
    const index = state.cart.findIndex(p => p.prize.id === position.prize.id);
    if (index === -1) {
      state.cart.push(position);
    } else {
      state.cart.splice(index, 1, position);
    }
  },
  [UNSET_CART_POSITION](state, position) {
    const index = state.cart.findIndex(p => p.prize.id === position.prize.id);
    state.cart.splice(index, 1);
  },
  [CLEAR_CART](state) {
    state.cart = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
